<template>
  <div>
    <input
      v-if="showInput"
      type="text"
      readonly="readonly"
      aria-readonly="true"
      autocomplete="off"
      aria-autocomplete="both"
      :ref="inputId"
      class="input"
      :id="inputId"
      :value="datePresentation"
      @click="showHideInput()"
      @focus="removeFocusFromInput"
    />
    <date-picker-popup
      v-if="popupShown"
      :ref="refDatePickerPopup"
      :key="'dp-mode-' + isRange"
      isRange
      :locale="$i18n.locale"
      v-model="mDates"
      :target="inputId"
      @updatedDates="updatedDates"
      @hide="hideDatePicker()"
      v-click-outside.bubble="{ exclude: [inputId], handler: 'hideDatePicker' }"
    />
  </div>
</template>

<script>
import DatePickerPopup from '../DatePickerPopup.vue'
export default {
  name: 'input-date-picker',

  props: {
    showInput: {
      type: Boolean,
      default: true,
    },
    value: {
      type: [Date, String, Object],
      default: function () {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },
    dateFormat: {
      type: String,
      default: 'shortDateFormat',
    },
    isRange: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    DatePickerPopup,
  },

  data() {
    return {
      inputId: `dpInput${new Date().getTime()}`,
      refDatePickerPopup: `popupWrapper${new Date().getTime()}`,
      popupShown: false,
      handleOutsideClick: null,
      mDates: this.value,
    }
  },

  computed: {
    datePresentation() {
      let output = ''

      if (this.mDates === null) {
        return 'Geen datum selectie'
      }

      if (!this.isRange) {
        output = this.$options.filters.dateFormat(
          this.mDates,
          this.$i18n.locale,
          this.dateFormat
        )
      } else {
        output = `${this.$options.filters.dateFormat(
          this.mDates.start,
          this.$i18n.locale,
          this.dateFormat
        )} - ${this.$options.filters.dateFormat(
          this.mDates.end,
          this.$i18n.locale,
          this.dateFormat
        )}`
      }
      return output
    },
  },

  watch: {
    value: {
      immediate: true,
      handler: function (val) {
        if (
          val === null ||
          (val.start.getFullYear() === 1900 && val.end.getFullYear() === 1900)
        ) {
          this.mDates = null
          return
        }
        this.mDates = { ...{}, ...val }
      },
    },
  },

  methods: {
    /**
     * Remove focus from input field
     */
    removeFocusFromInput(elm) {
      elm.target.blur()
    },

    showHideInput() {
      this.popupShown = !this.popupShown
    },

    hideDatePicker() {
      this.popupShown = false
    },

    doApplyBeforeClosing() {
      this.$refs[this.refDatePickerPopup].applyNewDates()
    },

    // Update dates
    updatedDates(val) {
      if (val === null) {
        val = {
          start: new Date(1900, 0, 1),
          end: new Date(1900, 0, 1),
        }
      }
      this.$emit('input', val)
      this.$emit('updatedDates', val)
    },
  },

  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        // Here's the click/touchstart handler
        // (it is registered below)
        vnode.context.handleOutsideClick = (e) => {
          e.stopPropagation()

          // Get the handler method name and the exclude array
          // from the object used in v-closable
          const { handler, exclude } = binding.value

          // This variable indicates if the clicked element is excluded
          let clickedOnExcludedEl = false

          if (typeof exclude !== 'undefined') {
            exclude.forEach((refName) => {
              // We only run this code if we haven't detected
              // any excluded element yet
              if (!clickedOnExcludedEl) {
                // Get the element using the reference name
                let excludedEl = vnode.context.$refs[refName]
                // See if this excluded element
                // is the same element the user just clicked on
                if (typeof excludedEl !== 'undefined') {
                  clickedOnExcludedEl = excludedEl.contains(e.target)
                }
              }
            })

            // We check to see if the clicked element is not
            // the dialog element and not excluded
            if (!el.contains(e.target) && !clickedOnExcludedEl) {
              // If the clicked element is outside the dialog
              // and not the button, then call the outside-click handler
              // from the same component this directive is used in
              vnode.context[handler]()
            }
          }
        }
        // Register click/touchstart event listeners on the whole page
        document.addEventListener('click', vnode.context.handleOutsideClick)
        // document.addEventListener('touchstart', vnode.context.handleOutsideClick)
      },

      unbind(el, binding, vnode) {
        // If the element that has v-closable is removed, then
        // unbind click/touchstart listeners from the whole page
        document.removeEventListener('click', vnode.context.handleOutsideClick)
        // document.removeEventListener('touchstart', vnode.context.handleOutsideClick)
      },
    },
  },
}
</script>

<style lang="scss" scoped></style>
