<template>
  <div class="search-wrapper columns is-unselectable">
    <div v-if="showSearchInput" class="column column-searchTerm">
      <input
        id="id_search_bar"
        type="text"
        class="input"
        autocomplete="off"
        aria-autocomplete="both"
        v-model="mSearchTerm"
        v-on:keyup.13="search"
        :placeholder="searchTermPlaceholder"
      />
    </div>

    <input-date-picker
      v-model="dateRange"
      :isRange="dateMode !== 'single'"
      @updatedDates="updatedDates"
      class="column column-date"
    />
    <div v-if="statusOptions" class="column column-status">
      <div id="id_search_status" class="select">
        <select v-model="mSelectedStatusId">
          <option
            v-for="(option, index) in statusOptions"
            :value="option.id"
            :key="'opt' + index"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>
    <div v-if="secondStatusOptions" class="column column-status">
      <div id="id_search_status" class="select">
        <select v-model="mSecondStatusId">
          <option
            v-for="(option, index) in secondStatusOptions"
            :value="option.id"
            :key="'opt' + index"
          >
            {{ option.text }}
          </option>
        </select>
      </div>
    </div>
    <div class="column column-button">
      <button
        id="id_search_button"
        class="button is-success"
        :class="{ 'is-loading': isLoading }"
        @click="search"
      >
        <span class="icon">
          <font-awesome-icon :icon="['fas', 'search']" />
        </span>
        <span v-text="$t('Components.UI.Form.Search.Button_Search')"></span>
      </button>
    </div>
  </div>
</template>

<script>
import InputDatePicker from './InputDatePicker.vue'

export default {
  props: {
    actionSearch: {
      type: Function,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      default: '',
    },
    searchTermPlaceholder: {
      type: String,
      default: '',
    },
    showSearchInput: {
      type: Boolean,
      default: true,
    },
    startDate: {
      type: Date,
      default: new Date(1900, 0, 1),
    },
    endDate: {
      type: Date,
      default: new Date(1900, 0, 1),
    },
    dateMode: {
      type: String,
      default: 'single',
    },
    selectedStatusId: {
      type: Number,
      default: 0,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
    secondStatusId: {
      type: Number,
      default: 0,
    },
    secondStatusOptions: {
      type: Array,
      default: null,
    },
    showDateInput: {
      type: Boolean,
      default: true,
    },

    useDateInput: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    InputDatePicker,
  },

  data() {
    return {
      dateRange: '',
      mSearchTerm: this.searchTerm,
      mSelectedStatusId: this.selectedStatusId,
      mSecondStatusId: this.secondStatusId,
      mUseDateInput: this.useDateInput,
      userChangedDate: false,
    }
  },

  computed: {},

  watch: {
    mSearchTerm: {
      immediate: true,
      handler: function (val) {
        if (!this.userChangedDate) {
          if (val !== '') {
            this.dateRange = null
            return
          }
          this.Init()
        }
      },
    },
  },

  created() {
    this.Init()
  },

  methods: {
    /**
     * Initialize data
     */
    Init() {
      let dates = this.startDate
      if (this.dateMode === 'range') {
        dates = {
          start: this.startDate,
          end: this.endDate,
        }
      }
      this.dateRange = dates
    },

    updatedDates(val) {
      this.userChangedDate = true
      this.dateRange = val
      this.search()
    },

    search() {
      let startDate =
        this.dateRange !== null ? this.dateRange.start : new Date(1900, 0, 1)
      let endDate =
        this.dateRange !== null ? this.dateRange.end : new Date(1900, 0, 1)

      let searchQuery = {
        searchTerm: this.mSearchTerm,
        useDateInput: this.dateRange !== null,
        startDate: startDate,
        endDate: endDate,
        selectedStatusId: this.mSelectedStatusId,
        secondStatusId: this.mSecondStatusId,
      }
      this.actionSearch(searchQuery)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-wrapper {
  justify-content: flex-start;
  margin: -7.5px;
  flex-wrap: wrap;

  .column {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 7.5px;
  }

  .column-searchTerm,
  .column-date {
    flex-basis: 23%;
    min-width: 220px;
  }

  .column-useDate {
    white-space: nowrap;
    .checkbox-date {
      margin-top: 4px;
    }
  }

  .column-status {
    select {
      min-width: 140px;
    }
  }
}
</style>
