<template>
  <transition-group name="fade" mode="out-in">
    <div key="dateTimePickerBackground" :class="`dpPopup-background`"></div>
    <div key="dateTimePicker" :ref="refName" :class="`dpPopup`">
      <div :class="`card`">
        <div class="columns-filters-datepicker">
          <div class="column-filters has-background-white-ter p-5">
            <div
              class="title is-6"
              v-text="$t('Components.UI.DatePickerPopup.Header_QuickSelection')"
            ></div>
            <div class="row">
              <a
                @click="unselectDates()"
                v-text="$t('Components.UI.DatePickerPopup.Link_NoSelection')"
              ></a>
            </div>
            <div class="row">
              <span
                v-text="$t('Components.UI.DatePickerPopup.Label_Day')"
              ></span>
              <a
                @click="setDateToToday()"
                v-text="getDayName(currentDayIndex - 1)"
              ></a>
              -
              <a
                @click="setDateToToday()"
                v-text="getDayName(currentDayIndex)"
              ></a>
              -
              <a
                @click="setDateToToday()"
                v-text="getDayName(currentDayIndex + 1)"
              ></a>
            </div>
            <div class="row">
              <span
                v-text="$t('Components.UI.DatePickerPopup.Label_Week')"
              ></span>
              <a
                @click="setDateToSelectedWeek(-1)"
                v-text="$t('Components.UI.DatePickerPopup.Link_Previous')"
              ></a>
              -
              <a
                @click="setDateToSelectedWeek(0)"
                v-text="$t('Components.UI.DatePickerPopup.Link_Current')"
              ></a>
              -
              <a
                @click="setDateToSelectedWeek(1)"
                v-text="$t('Components.UI.DatePickerPopup.Link_Next')"
              ></a>
            </div>
            <div class="row">
              <span
                v-text="$t('Components.UI.DatePickerPopup.Label_Month')"
              ></span>
              <a
                @click="setDateToMonth(currentMonth - 1)"
                v-text="getMonthName(currentMonth - 1)"
              ></a>
              -
              <a
                @click="setDateToMonth(currentMonth)"
                v-text="getMonthName(currentMonth)"
              ></a>
              -
              <a
                @click="setDateToMonth(currentMonth + 1)"
                v-text="getMonthName(currentMonth + 1)"
              ></a>
            </div>

            <div class="row">
              <span
                v-text="$t('Components.UI.DatePickerPopup.Label_Year')"
              ></span>
              <a @click="setYear(currentYear - 1)" v-text="currentYear - 1"></a>
              - <a @click="setYear(currentYear)" v-text="currentYear"></a> -
              <a @click="setYear(currentYear + 1)" v-text="currentYear + 1"></a>
            </div>
          </div>
          <div class="column-datepicker">
            <v-date-picker
              v-model="mDates"
              :mode="isRange ? 'range' : 'single'"
              @drag="onDrag"
              @dayclick="onDayClick"
              is-range
              :is-inline="true"
            />
            <div
              v-text="datePresentation"
              class="has-text-centered pb-2"
              :class="{ 'has-text-grey-light': isSelectingDates }"
            ></div>
          </div>
        </div>

        <footer v-if="confirmChoice" :class="`card-footer`">
          <a
            @click="hideDatePicker()"
            class="card-footer-item"
            v-text="$t('Form.Control.Cancel')"
          ></a>
          <a
            @click="applyNewDates()"
            class="card-footer-item has-text-weight-bold"
            :class="{ 'has-text-grey': isSelectingDates }"
            v-text="$t('Form.Control.Apply')"
          ></a>
        </footer>
      </div>
    </div>
  </transition-group>
</template>

<script>
import Vue from 'vue'
import VCalendar from 'v-calendar'
Vue.use(VCalendar, {})

export default {
  name: 'date-picker-popup',

  props: {
    target: {
      default: null,
      required: true,
    },

    minDate: {
      type: String,
      default: function () {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },

    maxDate: {
      type: String,
      default: function () {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },

    value: {
      type: [Date, String, Object],
      default: function () {
        return this.$options.filters.dateObjectIsoDateString(new Date())
      },
    },

    dateFormat: {
      type: String,
      default: 'shortDateFormat',
    },

    confirmChoice: {
      type: Boolean,
      default: true,
    },

    isRange: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      refName: `popup${this.target}`,
      refDatePicker: null,
      ready: false,
      trigger: document.getElementById(this.target),
      readyCounter: 0,
      mDates: this.initDates(),
      currentDayIndex: new Date().getDay(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      selectedDates: [],
      selectedDatesCounter: 2,
    }
  },

  computed: {
    isSelectingDates() {
      return this.mDates !== null && this.selectedDatesCounter < 2
    },

    datePresentation() {
      let output = ''

      if (this.mDates === null) {
        return 'Geen datum selectie'
      }

      if (!this.isRange) {
        output = this.$options.filters.dateFormat(
          this.mDates,
          this.$i18n.locale,
          this.dateFormat
        )
      } else {
        output = `${this.$options.filters.dateFormat(
          this.mDates.start,
          this.$i18n.locale,
          this.dateFormat
        )} - ${this.$options.filters.dateFormat(
          this.mDates.end,
          this.$i18n.locale,
          this.dateFormat
        )}`
      }
      return output
    },
  },

  created() {},

  mounted() {
    let self = this

    // Append modal to body
    document.body.appendChild(this.$el)
    self.ready = true
    let t = setTimeout(() => {
      document.addEventListener('scroll', self.scrollHandler, true)
      self.refDatePicker = self.$refs[self.refName]
      self.scrollHandler()
      clearTimeout(t)
    }, 50)
  },

  beforeDestroy() {
    this.resetBeforeDestroy()
  },

  methods: {
    /**
     * drag listener
     */
    onDrag(val) {
      if (val === null) {
        this.selectedDates = this.mDates
        this.selectedDatesCounter = 2
        return
      }
    },

    /**
     * day click listener
     */
    onDayClick(val) {
      this.selectedDatesCounter = this.selectedDatesCounter === 2 ? 1 : 2
    },

    /**
     * Initialize prop dates data
     */
    initDates() {
      if (this.value === null) {
        return this.value
      }

      if (!this.isRange) {
        // Is single
        if (typeof this.value === 'number') {
          return new Date(this.value)
        }
        if (typeof this.value === 'string') {
          return this.$options.filters.parseIsoDateStringToDate(this.value)
        }
        return this.value
      } else {
        // Is range
        return {
          start:
            typeof this.value.start === 'string'
              ? this.$options.filters.parseIsoDateStringToDate(this.value.start)
              : new Date(this.value.start),
          end:
            typeof this.value.end === 'string'
              ? this.$options.filters.parseIsoDateStringToDate(this.value.end)
              : new Date(this.value.end),
        }
      }
    },

    /**
     * Action need to be done before destroy
     */
    resetBeforeDestroy() {
      // Remove listeners
      removeEventListener('scroll', this.scrollHandler)

      // Hide date picker
      this.ready = false

      // Remove node from body
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
    },

    /**
     * Date time popup actions
     */
    hideDatePicker() {
      this.resetBeforeDestroy()
      this.$emit('hide')
    },

    /**------------------------------------
     * DATE CONTROLS
     ------------------------------------*/

    /**
     * Clear selected dates
     */
    unselectDates() {
      this.mDates = null
      this.applyNewDates()
    },

    /**
     * Set date range to today
     */
    setDateToToday() {
      let dateNow = new Date()

      this.mDates = {
        start: new Date(
          dateNow.getFullYear(),
          dateNow.getMonth(),
          dateNow.getDate(),
          12,
          0
        ),
        end: new Date(
          dateNow.getFullYear(),
          dateNow.getMonth(),
          dateNow.getDate(),
          12,
          0
        ),
      }

      this.applyNewDates()
    },

    getDayName(dayIndex) {
      if (dayIndex === -1) {
        // month is decemeber of past year
        dayIndex = 6
      }
      if (dayIndex === 7) {
        // month is januari of comming year
        dayIndex = 0
      }

      return this.$options.filters.getDayName(
        dayIndex + 1,
        this.$i18n.locale,
        false
      )
    },

    /**
     * Set date range to this week
     */
    setDateToSelectedWeek(weekIndex) {
      let date = new Date()
      if (weekIndex === -1) {
        date = new Date(new Date().setDate(date.getDate() - 7))
      }
      if (weekIndex === 1) {
        date = new Date(new Date().setDate(date.getDate() + 7))
      }

      let day = date.getDay()

      this.mDates = {
        start: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + ((day == 0 ? -6 : 1) - day),
          12,
          0
        ),
        end: new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + ((day == 0 ? 0 : 7) - day),
          12,
          0
        ),
      }

      this.applyNewDates()
    },

    getMonthName(monthIndex) {
      let month = monthIndex
      let year = this.currentYear

      if (month === -1) {
        // month is decemeber of past year
        month = 11
        year = year - 1
      }
      if (month === 12) {
        // month is januari of comming year
        month = 0
        year = year + 1
      }

      let output = this.$options.filters.getMonthName(
        month + 1,
        this.$i18n.locale,
        false
      )
      return (output += year !== this.currentYear ? ` (${year})` : '')
    },

    /**
     * Set date range to this month
     */
    setDateToMonth(monthIndex) {
      let month = monthIndex
      let year = this.currentYear

      if (month === -1) {
        // month is decemeber of past year
        month = 11
        year = year - 1
      }
      if (month === 12) {
        // month is januari of comming year
        month = 0
        year = year + 1
      }

      let dateNow = new Date()
      this.mDates = {
        start: new Date(
          year,
          month,
          1,
          dateNow.getHours(),
          dateNow.getMinutes()
        ),
        end: new Date(
          year,
          month + 1,
          0,
          dateNow.getHours(),
          dateNow.getMinutes()
        ),
      }

      // console.log(new Date(
      //   year,
      //   month + 1,
      //   0,
      //   dateNow.getHours(),
      //   dateNow.getMinutes()
      // )
      // )

      this.applyNewDates()
    },

    /**
     * Set date range to selected year
     */
    setYear(year) {
      let dateNow = new Date()
      this.mDates = {
        start: new Date(year, 0, 1, dateNow.getHours(), dateNow.getMinutes()),
        end: new Date(year, 11, 31, dateNow.getHours(), dateNow.getMinutes()),
      }

      this.applyNewDates()
    },

    /**
     * Update date picker location on scroll
     */
    scrollHandler() {
      // Check if datePickerRects is not undefined. After second time opening the date picker this issue occuers
      const viewPortWidth = window.innerWidth
      const viewPortHeight = window.innerHeight
      const triggerRects = this.trigger.getBoundingClientRect()
      const datePickerRects = this.refDatePicker.getBoundingClientRect()
      const vSpaceGap = 5
      const hSpaceGap = 15

      let canBeAddedAbove =
        triggerRects.top - vSpaceGap - datePickerRects.height > 15
      let canBeAddedBelow =
        viewPortHeight -
          (triggerRects.top +
            triggerRects.height +
            vSpaceGap +
            datePickerRects.height) >
        15

      // Position datepicker below the input
      this.refDatePicker.style.top = `${
        triggerRects.top + triggerRects.height + vSpaceGap
      }px`

      if (!canBeAddedAbove && canBeAddedBelow) {
        // Position datepicker below the input
        this.refDatePicker.style.top = `${
          triggerRects.top + triggerRects.height + vSpaceGap
        }px`
      }

      if (canBeAddedAbove && !canBeAddedBelow) {
        // Place the datepicker above input
        this.refDatePicker.style.top = `${
          triggerRects.top - vSpaceGap - datePickerRects.height
        }px`
      }

      if (!canBeAddedAbove && !canBeAddedBelow) {
        // Center the datepicker
        this.refDatePicker.style.top =
          (viewPortHeight - datePickerRects.height) / 2 + 'px'
      }

      // Position date picker vertical in the center of the trigger element
      this.refDatePicker.style.left = `${
        triggerRects.left - datePickerRects.width / 2 + triggerRects.width / 2
      }px`

      if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 -
          hSpaceGap <=
        0
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the left of the screen
        this.refDatePicker.style.left = `${hSpaceGap}px`
      } else if (
        triggerRects.left -
          datePickerRects.width / 2 +
          triggerRects.width / 2 +
          datePickerRects.width +
          hSpaceGap >=
        viewPortWidth
      ) {
        // Date picker falls to the right of the screen. Set date picker due to the value of the hSpaceGap from the right of the screen
        this.refDatePicker.style.left = null
        this.refDatePicker.style.right = `${hSpaceGap}px`
      }
    },

    /**
     * Proces dates and time methods
     */
    applyNewDates() {
      this.$emit('updatedDates', this.mDates)
      this.hideDatePicker()
    },
  },
}
</script>

<style lang="scss" scoped>
.dpPopup-background {
  position: fixed;
  z-index: 100001;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #333333, $alpha: 0.3);
}
.dpPopup {
  position: fixed;
  z-index: 100002;

  .columns-filters-datepicker {
    display: flex;
    overflow: hidden;

    .column-filters {
      position: relative;
      z-index: 1;
      // width: 250px;
      white-space: nowrap;
      // box-shadow: -2px 0 10px 1px rgba(124, 124, 124, 0.432);

      .row + .row {
        border-top: 1px solid #ebebeb;
        margin-top: 7px;
        padding-top: 7px;
      }

      .row {
        span {
          display: block;
          // width: 60px;
        }
      }
    }

    ::v-deep .vc-container {
      border-color: transparent;
    }
  }
}
</style>
